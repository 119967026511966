module.exports = [{
      plugin: require('/home/mcrowe/Programming/Personal/fns-gatsby/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/mcrowe/Programming/Personal/fns-gatsby/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1140,"quality":90,"linkImagesToOriginal":false},
    },{
      plugin: require('/home/mcrowe/Programming/Personal/fns-gatsby/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://factualsearch.news"},
    },{
      plugin: require('/home/mcrowe/Programming/Personal/fns-gatsby/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-131553259-1","respectDNT":true},
    }]
